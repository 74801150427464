import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import CartProvider from "./ContextAPI/CartContext.js"; // นำเข้า CartProvider
import { SubdomainProvider } from "./ContextAPI/SubdomainContext.js";
//import Routers from "./Routers";
import About from "./components/About";
import AllProductPage from "./components/AllProductPage";
import Login from "./components/Auth/Login/index";
import Profile from "./components/Auth/Profile";
import Signup from "./components/Auth/Signup";
import BecomeSaller from "./components/BecomeSaller";
import Blogs from "./components/Blogs";
import Blog from "./components/Blogs/Blog.jsx";
import CardPage from "./components/CartPage";
import CheakoutPage from "./components/CheakoutPage";
import Contact from "./components/Contact";
import Faq from "./components/Faq";
import FlashSale from "./components/FlashSale";
import FourZeroFour from "./components/FourZeroFour";
import HomeThree from "./components/HomeThree";
import OrderPlaced from "./components/OrderPlaced/index.jsx";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProductsCompaire from "./components/ProductsCompaire/index";
import SallerPage from "./components/SallerPage";
import Sallers from "./components/Sellers";
import SingleProductPage from "./components/SingleProductPage";
import TermsCondition from "./components/TermsCondition/index";
import TrackingOrder from "./components/TrackingOrder";
import Wishlist from "./components/Wishlist";
//1

export default function App() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <CartProvider>
        <SubdomainProvider>
          {" "}
          {/* ห่อ components ทั้งหมดด้วย CartProvider */}
          <Router>
            <Routes>
              <Route exact path="/" element={<HomeThree />} />
              <Route exact path="/all-products" element={<AllProductPage />} />
              <Route exact path="/single-product/:productId" element={<SingleProductPage />} />
              <Route exact path="/cart" element={<CardPage />} />
              <Route exact path="/checkout" element={<CheakoutPage />} />
              <Route exact path="/wishlist" element={<Wishlist />} />
              <Route exact path="/flash-sale" element={<FlashSale />} />
              <Route exact path="/saller-page" element={<SallerPage />} />
              <Route exact path="/products-compaire" element={<ProductsCompaire />} />
              <Route exact path="/sallers" element={<Sallers />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/blogs" element={<Blogs />} />
              <Route exact path="/blogs/blog" element={<Blog />} />
              <Route exact path="/tracking-order" element={<TrackingOrder />} />
              <Route exact path="/contact" element={<Contact />} />
              <Route exact path="/faq" element={<Faq />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/signup" element={<Signup />} />
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/become-saller" element={<BecomeSaller />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/terms-condition" element={<TermsCondition />} />
              <Route exact path="/order-placed/:orderId" element={<OrderPlaced />} />
              <Route exact path="*" element={<FourZeroFour />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Router>
        </SubdomainProvider>
      </CartProvider>
    </div>
  );
}
//sss
