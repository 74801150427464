// SubdomainContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const SubdomainContext = createContext();

const fetchSlugSetting = async () => {
    // อ่านไฟล์ txt ที่กำหนด
    const response = await fetch('setting.txt'); // แก้ไขเป็น path ที่แท้จริงของไฟล์
    const text = await response.text();
    return text.trim();
};

export const SubdomainProvider = ({ children }) => {
    const [slug, setSlug] = useState('');

    useEffect(() => {
        const determineSlug = async () => {
            const setting = await fetchSlugSetting();

            if (setting === '1') {
                // อ่าน subdomain
                const hostname = window.location.hostname;
                const sub = hostname.split('.')[0];
                if (sub !== 'www' && sub !== 'yourmaindomain') { // แก้ไข 'yourmaindomain' เป็น domain หลักของคุณ
                    setSlug(sub);
                }
            } else if (setting === '2') {
                // อ่านค่าด้านหลัง domain
                const pathname = window.location.pathname;
                const pathSlug = pathname.split('/')[1]; // แก้ไขการแยก path ตามที่ต้องการ
                setSlug(pathSlug);
            } else if (setting === '3') {
                // กำหนดค่า slug ตามที่กำหนดในไฟล์
                const response = await fetch('slug_value.txt'); // แก้ไขเป็น path ของไฟล์ที่กำหนดค่า slug
                const slugValue = await response.text();
                setSlug(slugValue.trim());
            }
        };

        determineSlug();
    }, []);

    return (
        <SubdomainContext.Provider value={{ slug }}>
            {children}
        </SubdomainContext.Provider>
    );
};

export const useSubdomain = () => useContext(SubdomainContext);
