import { useEffect, useState } from "react";
//import DiscountBanner from "../Home/DiscountBanner";
// import DrawerThree from "../Mobile/DrawerThree";
import Footer from "./Footers/Footer";
import Header from "./Headers/HeaderOne";

export default function LayoutHomeThree({ children, childrenClasses, type, slug, webLogo, mainColor }) {
    useEffect(() => {
        //alert(slug);
    }, []);
    const [drawer, setDrawer] = useState(false);
    return (
        <>
            {/* <DrawerThree open={drawer} action={() => setDrawer(!drawer)} slug={slug} mainColor={mainColor} /> */}

            <div className="w-full overflow-x-hidden">
                <Header type={2} drawerAction={() => setDrawer(!drawer)} slug={slug} webLogo={webLogo} mainColor={mainColor} />
                <div className={`w-full  ${childrenClasses || "pt-[30px] pb-[60px]"}`}>
                    {children && children}
                </div>
                {/*<DiscountBanner type={1} />*/}
                <Footer type={1} slug={slug} webLogo={webLogo} mainColor={mainColor} />
            </div>
        </>
    );
}