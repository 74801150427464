import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSubdomain } from '../../ContextAPI/SubdomainContext';
import datas from "../../data/products.json";
import SectionStyleThree from '../Helpers/SectionStyleThree';
import SectionStyleThree3Image from '../Helpers/SectionStyleThree3Image';
import LayoutHomeThree from "../Partials/LayoutHomeThree";
import Banner from './Banner';

/*import SectionStyleFour from "../Helpers/SectionStyleFour";
import SectionStyleThree from '../Helpers/SectionStyleThree';
import SectionStyleTwo from "../Helpers/SectionStyleTwo";
import ViewMoreTitle from "../Helpers/ViewMoreTitle";
import LayoutHomeThree from "../Partials/LayoutHomeThree";
import Banner from './Banner';
import BrandSection from './BrandSection';
import CampaignCountDown from "./CampaignCountDown";
import ProductsAds from "./ProductsAds";*/

export default function HomeThree() {
  const { products } = datas;
  const brands = [];
  products.forEach((product) => {
    brands.push(product.brand);
  });
  const { slugtemp } = useParams();
  const { slug } = useSubdomain();
  const testImageUrls = [
    "img1.jpg",
    "img2.jpg",
    "img3.jpg",
  ];

  const [bannerImage, setBannerImage] = useState([['/assets/images/banner_long_1__2500__1251__.jpg']]);
  const [bannerImage2, setBannerImage2] = useState([['/assets/images/mbanner_1_1.jpeg']]);
  const [businessData, setBusinessData] = useState();
  const [productData, setProductData] = useState();
  const [productData2, setProductData2] = useState();
  const [webLogo, setWebLogo] = useState();
  const [shopfrontData, setShopfrontData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [mainColor, setMainColor] = useState('white');

  useEffect(() => {

  }, []);


  useEffect(() => {
    console.log("subdomain === " + slug);

    const fetchAllWebData = async (slug) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/businesses/getShopfrontMainBannerImageURL/` + slug);
        if (response.data) {
          setBusinessData(response.data.businessData);

          //const sortedData = [...data].sort((a, b) => a.order - b.order); //ในกรณีอยาก copy
          (response.data.shopfrontData[0].layouts).sort((a, b) => a.order - b.order);

          const data = response.data.shopfrontData[0].layouts;
          const extractImageUrls = key => data.map(item => item.content.map(content => content[key]));
          setBannerImage(extractImageUrls("imageUrl"));
          setBannerImage2(extractImageUrls("imageUrl2"));
          (response.data.productData).sort((a, b) => {
            const dateA = new Date(a.lastUpdate);
            const dateB = new Date(b.lastUpdate);
            return dateB - dateA;
          });
          setProductData(response.data.productData);
          setProductData2(response.data.productData);

          setShopfrontData(response.data.shopfrontData[0]);
          setCategoryData(response.data.categoryData[0]);
          setWebLogo(response.data.shopfrontData[0].logoImageURL);
          //alert(response.data.shopfrontData[0].logoImageURL);
        } else {
          //await alert("error1 ---- " + response.data);
        }

      } catch (error) {
        //await alert("error2 ---- " + error);
        console.error("Failed to fetch banner image:", error);
      }
    };

    fetchAllWebData(slug);

  }, [slug]);


  useEffect(() => {
    console.log(bannerImage);
  }, [bannerImage]);


  useEffect(() => {
    if (shopfrontData) {
      const colorR = shopfrontData.mainColor;
      //alert(JSON.stringify(shopfrontData, null, 2));
      const rgbaString = `rgba(${colorR[0]}, ${colorR[1]}, ${colorR[2]}, ${colorR[3] / 255})`;
      //setBannerImage(shopfrontData.layouts[0].content[0].imageUrl);
      setMainColor(rgbaString);
    }
  }, [shopfrontData]);

  return (
    <>
      <LayoutHomeThree type={3} childrenClasses="pt-0" slug={slug} webLogo={webLogo} mainColor={mainColor}>
        {shopfrontData && shopfrontData.layouts && (
          <>
            <ul>
              {shopfrontData.layouts.map((layout, index) => (
                <li key={index}>
                  {layout.type === 1 ? (
                    <div style={{ marginTop: index > 0 ? '0px' : '0px' }}>
                      <Banner className="banner-wrapper mb-[60px]" backgroundImageURL={bannerImage[index]} backgroundImageURL2={bannerImage2[index]} isFullWidth={layout.layoutOption?.isFullWidth ?? true} />
                    </div>

                  ) : layout.type === 2 ? (
                    <>
                      <div style={{ marginTop: '100px', marginBottom: '100px' }}>

                        <SectionStyleThree
                          type={1}
                          products={productData}
                          sectionTitle="ตัวส่วนแสดง Product แบบ1"
                          seeMoreUrl={`/all-products`}
                          layout={layout}
                          layoutOption={layout.layoutOption}
                          amount={4}
                          className="new-products mb-[60px]"
                          slug={slug}
                        />
                      </div>
                    </>
                  ) : layout.type === 3 ? (
                    <>
                      <div style={{ marginTop: '100px', marginBottom: '100px' }}>
                        <SectionStyleThree
                          type={1}
                          products={productData}
                          sectionTitle="ตัวส่วนแสดง Product แบบที่ 1"
                          seeMoreUrl={`/all-products`}
                          amount={4}
                          layout={layout}
                          layoutOption={layout.layoutOption}
                          className="new-products mb-[60px]"
                          slug={slug}
                        />
                      </div>
                    </>
                  ) : layout.type === 5 ? (
                    <>
                      <div style={{ marginTop: '0px', marginBottom: '0px' }}>
                        <SectionStyleThree3Image
                          type={1}
                          layout={layout}
                          sectionTitle="ตัวส่วนแสดง Product แบบที่ 1"
                          seeMoreUrl={`/all-products`}
                          amount={4}
                          className="new-products mb-[60px]"
                          slug={slug}
                        />
                      </div>

                    </>
                  ) : layout.type === 7 ? (
                    <div style={{ width: '100vw', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                      <div style={{
                        width: (layout.layoutOption.isFullWidth ?? true) ? '100vw' : '1400px',
                        paddingTop: `${layout.layoutOption.spaceVertical ?? 100}px`,
                        paddingBottom: `${layout.layoutOption.spaceVertical ?? 100}px`,
                      }} >
                        {layout.layoutOption.isShowDivider ? <hr style={{ height: '2px', border: 'none', backgroundColor: 'lightgrey' }} /> : null}
                      </div>

                    </div>
                  ) : (<></>)

                  }
                </li>
              ))}
            </ul>
          </>
        )}

      </LayoutHomeThree >
    </>
  );
}
